







import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';

// этот компонент работает только при динамическом импорте, иначе крашится в режиме ssr
@Component({
  name: 'AppColumnChartWithRadio',
  props: {
    data: Array,
    titleText: String,
    onColumnClick: Function,
  },
  components: {
    highcharts: Chart,
  },
})
export default class AppColumnChartWithRadio extends Vue {
  // props
  data: any;
  titleText: string;
  onColumnClick: () => any;

  initOptions() {
    return {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
        align: 'left',
      },
      plotOptions: {
        column: {
          maxPointWidth: 30,
          minPointLength: 66,
          cursor: 'pointer',
          borderRadius: 4,
          dataLabels: {
            allowOverlap: true,
          },
          events: {
            click: this.onColumnClick,
          },
        },
      },
      xAxis: {
        type: 'category',
        lineWidth: 0,
        labels: {
          // autoRotation: [ -45, -90 ],
          style: {
            fontSize: '13px',
            fontFamily: 'Onest',
            fontWeight: 400,
          },
        },
      },
      yAxis: {
        visible: false,
        min: 0,
        title: {
          text: 'Population (millions)',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: 'Сумма за год: <b>{point.y:.1f} ₽</b>',
        borderRadius: 20,
        borderWidth: 0,
        shadow: false,
      },
      series: [ {
        name: 'Population',
        colors: [
          '#336FEE',
        ],
        colorByPoint: true,
        groupPadding: 0,
        data: this.data,
        allowPointSelect: true,
        states: {
          select: {
            borderColor: '#24BA96',
            color: '#24BA96',
          },
        },
        dataLabels: {
          enabled: true,
          shadow: false,
          rotation: -90,
          inside: true,
          verticalAlign: 'top',
          format: '{point.y} ₽',
          useHTML: true,
          // format: '{point.y:.1f}', // one decimal
          y: 35,
          style: {
            color: '#FFFFFF',
            fontSize: '14px',
            fontFamily: 'Onest',
            fontWeight: 400,
          },
        },
      } ],
    };
  }
}
